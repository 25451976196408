import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"

import { useGTMInit} from '@/hooks/useGTM.js'


const Index = () =>{
  React.useEffect(()=>{
    useGTMInit('support','XREAL_Beam_MANUAL_CN&EN&JP&KR_pdf')
  },[])
  return(
    <>
      <DownloadFilePage
        downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_Beam_MANUAL_CN&EN&JP&KR.pdf`}
      />
    </>
  )
} 

export default Index